<template>
  <div class="col-12 c g">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-list"></i>
          المشرفين
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>الاسم</th>
              <th>اسم المستخدم</th>
              <th>النوع</th>
              <th>ملاحظات</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <template v-for="bus in admins">
                <tr
                  :key="bus._id"
                  v-if="
                    (checkPer('admins-view') && !bus.bus) ||
                    (checkPer('admins_bus-view') && bus.bus)
                  "
                >
                  <td>
                    {{ bus.name }}
                  </td>
                  <td>
                    {{ bus.username }}
                  </td>
                  <td>
                    {{ bus.bus ? "للباصات" : "للموقع" }}
                  </td>
                  <td>
                    {{ bus.notes }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary btn-sm"
                      v-if="
                        (checkPer('admins-edit') && !bus.bus) ||
                        (checkPer('admins_bus-edit') && bus.bus)
                      "
                      @click="$router.push('/admins/add#' + bus._id)"
                    >
                      <i class="fa fa-edit"></i>
                      تعديل
                    </button>
                    &nbsp;
                    <button
                      v-if="
                        (checkPer('admins-delete') && !bus.bus) ||
                        (checkPer('admins_bus-delete') && bus.bus)
                      "
                      class="btn text-danger btn-sm"
                      @click="deleteBus(bus)"
                    >
                      <i class="fa fa-trash"></i>
                      حذف
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BModal,
    draggable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      admins: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    if (!checkPer("admins") && !checkPer("admins_bus")) {
      this.$router.push("/per");
      return false;
    }
    $.post(api + "/user/admins/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.admins = r.response;
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    deleteBus(bus) {
      var g = this;
      if (confirm("متأكد من حذف المشرف نهائياً؟ ربما يؤثر على باقي السجلات")) {
        $.post(api + "/user/admins/delete", {
          jwt: g.user.jwt,
          id: bus._id,
        })
          .then(function (r) {
            location.reload();
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      }
    },
  },
};
</script>