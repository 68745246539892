var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 c g"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-12 table-responsive"},[_c('table',{staticClass:"table table-hover table-bordered"},[_vm._m(1),_c('tbody',[_vm._l((_vm.admins),function(bus){return [(
                  (_vm.checkPer('admins-view') && !bus.bus) ||
                  (_vm.checkPer('admins_bus-view') && bus.bus)
                )?_c('tr',{key:bus._id},[_c('td',[_vm._v(" "+_vm._s(bus.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(bus.username)+" ")]),_c('td',[_vm._v(" "+_vm._s(bus.bus ? "للباصات" : "للموقع")+" ")]),_c('td',[_vm._v(" "+_vm._s(bus.notes)+" ")]),_c('td',[(
                      (_vm.checkPer('admins-edit') && !bus.bus) ||
                      (_vm.checkPer('admins_bus-edit') && bus.bus)
                    )?_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.$router.push('/admins/add#' + bus._id)}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" تعديل ")]):_vm._e(),_vm._v("   "),(
                      (_vm.checkPer('admins-delete') && !bus.bus) ||
                      (_vm.checkPer('admins_bus-delete') && bus.bus)
                    )?_c('button',{staticClass:"btn text-danger btn-sm",on:{"click":function($event){return _vm.deleteBus(bus)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" حذف ")]):_vm._e()])]):_vm._e()]})],2)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header text-center"},[_c('h4',[_c('i',{staticClass:"fa fa-list"}),_vm._v(" المشرفين ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('th',[_vm._v("الاسم")]),_c('th',[_vm._v("اسم المستخدم")]),_c('th',[_vm._v("النوع")]),_c('th',[_vm._v("ملاحظات")]),_c('th',[_vm._v("خيارات")])])}]

export { render, staticRenderFns }